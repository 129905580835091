<template>
  <LxpMobileHeader v-if="!isLoading" :title="item[0].crseNm">
    <template v-slot:left>
      <div class="util util-back">
        <router-link :to="{name: 'MyPageMain', query: {'view': 'history'}}" class="util-actions util-actions-back">
          <i class="icon-history-back"></i>
        </router-link>
      </div>
    </template>
  </LxpMobileHeader>
  <!-- main-content -->
  <br>
  <br>
  <div v-if="!isLoading" class="main-content main-component">
    <div class="support-view-container">
      <div class="view-contents">
        <section class="content-section">
          <div class="section-content">
            <div class="content-item global-border">
              <div class="accordion" :class="{'is-active' : isActiveA}">
                <a href="javascript:" class="accordion-toggle-action" @click="clickToggle('partA')">
                  <div class="accordion-row global-space">
                    <div class="accordion-column">
                      <div class="accordion-title big">Information</div>
                    </div>
                    <div class="accordion-icon"><i class="icon-arrow"></i></div>
                  </div>
                </a>
                <div class="accordion-target">
                  <ul class="accordion-sub">
                    <li>
                      <div class="context-list">
                        <article>
                          <p class="global-mobile-min-width">성명</p>
                          <p class="context">{{session.lrnerNm}}</p>
                        </article>
                        <article>
                          <p class="global-mobile-min-width">직원번호</p>
                          <p class="context">{{session.lrnerId}}</p>
                        </article>
                        <article>
                          <p class="global-mobile-min-width">응시일자</p>
                          <p class="context">{{item[0].examDd ? ymdStrToFormat(item[0].examDd, '.') : '-'}}</p>
                        </article>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="content-section">
          <div class="section-content">
            <div class="content-item global-border">
              <div class="accordion" :class="{'is-active' : isActiveB}">
                <a href="javascript:" class="accordion-toggle-action" @click="clickToggle('partB')">
                  <div class="accordion-row global-space">
                    <div class="accordion-column">
                      <div class="accordion-title big">Test Result</div>
                    </div>
                    <div class="accordion-icon"><i class="icon-arrow"></i></div>
                  </div>
                </a>
                <div class="accordion-target ">
                  <ul class="accordion-sub">
                    <li>
                      <div class="title-category">
                        <h4 class="global-font-gold">Speaking</h4>
                      </div>
                      <div class="context-list">
                        <article>
                          <p class="global-mobile-min-width">Grade</p>
                          <p class="context">{{item[0].convGrd != null ? item[0].convGrd+'등급' : '-'}}</p>
                        </article>
                        <article>
                          <p class="global-mobile-min-width">Score</p>
                          <p class="context">{{item[0].convScore}}점</p>
                        </article>
                        <article>
                          <p class="global-mobile-min-width">Comment</p>
                          <p class="context">{{item[0].convCmnt ? item[0].convCmnt : '-'}}</p>
                        </article>
                      </div>
                    </li>
                    <li>
                      <div class="title-category">
                        <h4 class="global-font-gold">Writing</h4>
                      </div>
                      <div class="context-list">
                        <article>
                          <p class="global-mobile-min-width">Grade</p>
                          <p class="context">{{item[0].compoGrd != null ? item[0].compoGrd+'등급' : '-'}}</p>
                        </article>
                        <article>
                          <p class="global-mobile-min-width">Score</p>
                          <p class="context">{{item[0].compoScore}}점</p>
                        </article>
                        <article>
                          <p class="global-mobile-min-width">Comment</p>
                          <p class="context">{{item[0].compoCmnt ? item[0].compoCmnt : '-'}}</p>
                        </article>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="content-section">
          <div class="section-content">
            <div class="content-item global-border">
              <div class="accordion" :class="{'is-active' : isActiveC}">
                <a href="javascript:" class="accordion-toggle-action" @click="clickToggle('partC')">
                  <div class="accordion-row global-space">
                    <div class="accordion-column">
                      <div class="accordion-title big">Speaking test Details</div>
                    </div>
                    <div class="accordion-icon"><i class="icon-arrow"></i></div>
                  </div>
                </a>
                <div class="accordion-target">
                  <ul class="accordion-sub">
                    <li>
                      <div class="context-list">
                        <div class="title-category">
                          <h4 class="global-font-gold" style="margin-right: 85px; display:inline">Criteria</h4>
                          <h4 class="global-font-gold" style="display:inline">Comments</h4>
                        </div>
                        <article>
                          <p class="global-mobile-min-width">Vocabulary</p>
                          <p class="context">{{item[0].vocCmnt ? item[0].vocCmnt : '-'}}</p>
                        </article>
                        <article>
                          <p class="global-mobile-min-width">Pronunciation</p>
                          <p class="context">{{item[0].proncCmnt ? item[0].proncCmnt : '-'}}</p>
                        </article>
                        <article>
                          <p class="global-mobile-min-width">Grammar</p>
                          <p class="context">{{item[0].grrCmnt ? item[0].grrCmnt : '-'}}</p>
                        </article>
                        <article>
                          <p class="global-mobile-min-width">Fluency</p>
                          <p class="context">{{item[0].fludCmnt ? item[0].fludCmnt : '-'}}</p>
                        </article>
                        <article>
                          <p class="global-mobile-min-width">Comprehension</p>
                          <p class="context">{{item[0].intpnCmnt ? item[0].intpnCmnt : '-'}}</p>
                        </article>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="content-section">
          <div class="section-content">
            <div class="content-item global-border">
              <div class="accordion" :class="{'is-active' : isActiveD}">
                <a href="javascript:" class="accordion-toggle-action" @click="clickToggle('partD')">
                  <div class="accordion-row global-space">
                    <div class="accordion-column">
                      <div class="accordion-title big">General Review</div>
                    </div>
                    <div class="accordion-icon"><i class="icon-arrow"></i></div>
                  </div>
                </a>
                <div class="accordion-target">
                  <ul class="accordion-sub">
                    <li>
                      <div class="context-list">
                        <article>
                          <p class="global-mobile-min-width">Strong Points</p>
                          <p class="context">{{item[0].strDesc ? item[0].strDesc : '-'}}</p>
                        </article>
                        <article>
                          <p class="global-mobile-min-width">Weak Points</p>
                          <p class="context">{{item[0].weakDesc ? item[0].weakDesc : '-'}}</p>
                        </article>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="content-section">
          <div class="section-content">
            <div class="content-item global-border">
              <div class="accordion" :class="{'is-active' : isActiveE}">
                <a href="javascript:" class="accordion-toggle-action" @click="clickToggle('partE')">
                  <div class="accordion-row global-space">
                    <div class="accordion-column">
                      <div class="accordion-title big">Interviewer</div>
                    </div>
                    <div class="accordion-icon"><i class="icon-arrow"></i></div>
                  </div>
                </a>
                <div class="accordion-target">
                  <ul class="accordion-sub">
                    <li>
                      <div class="context-list">
                        <article>
                          <p class="global-mobile-min-width">1st Interviewer</p>
                          <p class="context">{{item[0].frstInterViewrNm ? item[0].frstInterViewrNm : '-'}}</p>
                        </article>
                        <article>
                          <p class="global-mobile-min-width">Date</p>
                          <p class="context">{{item[0].evlDt ? ymdStrToFormat(item[0].evlDt, '.') : '-'}}</p>
                        </article>
                        <article>
                          <p class="global-mobile-min-width">2nd Interviewer</p>
                          <p class="context">{{item[0].sendInterViewrNm ? item[0].sendInterViewrNm : '-'}}</p>
                        </article>
                        <article>
                          <p class="global-mobile-min-width">Remarks</p>
                          <p class="context">-</p>
                        </article>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br>
        <br>
        <br>
        <br>
      </div>
    </div>
  </div>
  <!-- //main-content -->
</template>

<script>
import {ref, computed} from "vue";
import {useStore} from "vuex";
import {ymdStrToFormat} from "@/assets/js/util";
import {getListFunc} from "@/assets/js/ui.init";
import {ACT_GET_LEARN_GLOBAL_LIST} from "@/store/modules/learn/learn";
import {useRoute} from "vue-router";
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";

export default {
  name: 'MobileMyPageGlobal',
  components: {LxpMobileHeader},
  setup() {
    const store = useStore();
    const route = useRoute();
    const item = ref([]);
    const isLoading = ref(true);

    const session = computed(() => store.state.auth.session);
    const isActiveA = ref(true);
    const isActiveB = ref(true);
    const isActiveC = ref(true);
    const isActiveD = ref(true);
    const isActiveE = ref(true);

    const clickToggle = (part) => {
      if('partA'===part) isActiveA.value = !isActiveA.value;
      if('partB'===part) isActiveB.value = !isActiveB.value;
      if('partC'===part) isActiveC.value = !isActiveC.value;
      if('partD'===part) isActiveD.value = !isActiveD.value;
      if('partE'===part) isActiveE.value = !isActiveE.value;
    }

    getListFunc(`learn/${ACT_GET_LEARN_GLOBAL_LIST}`, {frlngGradeSn : route.params.frlngGradeSn}, item, null, () => {
      isLoading.value = false;
    })

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

    return {
      clickToggle, session,item,isLoading,
      isActiveA, isActiveB, isActiveC, isActiveD, isActiveE, ymdStrToFormat
    }
  }
};
</script>
<style scoped>
.global-mobile-min-width {min-width: 140px !important; font-weight:bold;}
.global-font-gold {color: var(--kb-gold);}
.global-border {border-top: 1px solid var(--kb-light-silver);}
.global-space {padding-top: 15px !important; margin-bottom: -10px !important;}
</style>